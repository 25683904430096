<template>
  <main :data-from-date="payload.fromDate">
    <section class="flex flex-wrap items-center justify-between w-full p-6 bg-white rounded-lg shadow">
      <div class="flex items-center justify-between">
        <h3 class="text-lg text-gray-800">Download Report</h3>
        <!-- <p class="text-gray-dark flex items-center text-sm">
          Last updated on {{ moment(currentTime).format('hh:mm A DD MMM') }}
        </p> -->
      </div>
      <div class="flex flex-wrap space-x-3">
        <div class="date-select-actions md:flex items-center hidden">
          <input id="todayRadioInput" type="radio" name="dateRangeRadioSelector" value="today" @change="updateDate" />
          <label for="todayRadioInput" class="nav-item m0">
            <span class="nav-link">Today</span>
          </label>
          <input id="7daysRadioInput" type="radio" name="dateRangeRadioSelector" value="7days" @change="updateDate" />
          <label for="7daysRadioInput" class="nav-item m0">
            <span class="nav-link">7 Days</span>
          </label>
          <input id="14daysRadioInput" type="radio" name="dateRangeRadioSelector" value="14days" @change="updateDate" />
          <label for="14daysRadioInput" class="nav-item m0">
            <span class="nav-link">14 Days</span>
          </label>
          <input id="monthRadioInput" type="radio" name="dateRangeRadioSelector" value="month" @change="updateDate" />
          <label for="monthRadioInput" class="nav-item m0">
            <span class="nav-link">Last Month</span>
          </label>
        </div>
        <div class="relative">
          <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">From Date</p>
          <input v-model="startDate" class="focus:outline-none px-4 py-2 text-sm font-semibold leading-tight text-gray-700 bg-gray-100 border rounded" type="date" @change="resetInputs" />
        </div>
        <div class="relative">
          <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">To Date</p>
          <input v-model="endDate" class="focus:outline-none px-4 py-2 text-sm font-semibold leading-tight text-gray-700 bg-gray-100 border rounded" type="date" @change="resetInputs" />
        </div>
        <div class="relative">
          <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">Report type</p>
          <select v-model="selectedReport" class="focus:outline-none block w-full h-full px-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none">
            <option value="">All</option>
            <option v-for="report in reportTypes" :key="report" :value="report" class="capitalize">
              {{ report.split('_').join(' ') }}
            </option>
          </select>
          <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <button
          v-if="startDate && endDate && selectedReport && !executions.length"
          :class="{ loading: request.keys.includes('executeAthenaQuery') && request.inProgress }"
          :disabled="request.keys.includes('executeAthenaQuery') && request.inProgress"
          class="button px-6 mr-5 text-sm text-white bg-blue-500 rounded"
          @click="executeAthenaQuery(payload)"
        >
          Generate new report
          <ring-loader />
        </button>
      </div>
    </section>

    <ExecutionTable :filters="payload" @clearFilters="clearFilters" @refreshTableData="refreshTableData" />
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import calenderMixin from '@/mixins/calender-mixin';

export default {
  name: 'Download',
  components: {
    ExecutionTable: () => import('@/components/ExecutionTable.vue'),
  },
  mixins: [calenderMixin],
  data() {
    return {
      currentTime: new Date(),
      reportTypes: ['card_completed', 'card_bookmarked', 'card_source_visited', 'card_viewed', 'journey_completion_report', 'user_card_rated'],
      selectedReport: '',
    };
  },
  computed: {
    ...mapGetters(['request', 'executions']),
    payload() {
      const payload = {
        toDate: this.endDate,
        fromDate: this.startDate,
        report: this.selectedReport,
      };
      if ((payload.toDate && payload.fromDate) || (!payload.toDate && !payload.fromDate)) this.fetchQueryExecHistory(payload);
      return payload;
    },
  },
  async beforeMount() {
    await this.fetchUserRole();
  },
  methods: {
    ...mapActions(['executeAthenaQuery', 'fetchQueryExecHistory', 'fetchUserRole']),
    clearFilters() {
      this.endDate = '';
      this.startDate = '';
      this.selectedReport = '';
    },
    refreshTableData() {
      this.fetchQueryExecHistory(this.payload);
    },
    resetInputs() {
      document.querySelectorAll('input[name="dateRangeRadioSelector"]').forEach((x) => {
        const elem = x;
        elem.checked = false;
      });
    },
  },
};
</script>

<style lang="postcss">
.date-select-actions {
  /* @apply space-x-2; */
  input {
    @apply fixed left-0 opacity-0 invisible;
    margin-left: -9999px;

    &:checked {
      /* stylelint-disable-next-line */
      + label {
        @apply bg-blue-50 text-blue-700;
      }
    }
  }
  label {
    @apply inline-block rounded-lg font-medium leading-6 px-3 focus:outline-none hover:text-blue-600 focus:text-blue-600 cursor-pointer text-sm border border-gray-200 text-gray-700;

    &:not(:first-of-type) {
      @apply ml-2;
    }
  }
}
</style>
