<template>
  <section class="flex flex-col items-center justify-center h-screen text-lg text-gray-700">
    <!-- <button @click="login">Open Okta</button> -->
    <img class="w-48 mb-3" src="@/assets/genome-header.svg" />
    <p class="mb-20">Taking you to Okta login page...</p>
  </section>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'LoginPage',

  // TODO: REMOVE THESE
  /*
    ==============================
    api.genpact.com based auth
    ==============================
  */
  created() {
    const queryObj = { ...this.$route.query };
    // eslint-disable-next-line prefer-destructuring
    if (Array.isArray(queryObj.redirect)) queryObj.redirect = queryObj.redirect[0];
    if (queryObj.redirect) {
      if (decodeURIComponent(this.$store.state.route.query.redirect) !== '/') {
        const redirect = decodeURIComponent(this.$store.state.route.query.redirect)
          .replace(/\/login/g, '')
          .replace(/(accessToken=)[^&]+/, '')
          .replace(/\?redirect=/g, '');
        if (decodeURIComponent(queryObj.redirect) !== redirect) {
          queryObj.redirect = redirect;
          this.$router.replace({ query: queryObj });
        }
      } else {
        delete queryObj.redirect;
        this.$router.replace({ query: queryObj });
      }
    } else if (queryObj.redirect === '') {
      delete queryObj.redirect;
      this.$router.replace({ query: queryObj });
    }
    window.location.href = `https://api.genpact.com/login?redirect=${window.location.href.replace(/\/login/g, '').replace(/\?redirect=/g, '')}`;
  },
  /*
    ==============================
    end api.genpact.com based auth
    ==============================
  */

  // mounted() {
  //   setTimeout(() => {
  //     Auth.federatedSignIn({ provider: 'ApiSvcIdentityPool' });
  //   }, 2000);
  // },
  methods: {
    login() {
      Auth.federatedSignIn({ provider: 'ApiSvcIdentityPool' });
    },
  },
};
</script>
