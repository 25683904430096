import Vue from 'vue';
import Vuex from 'vuex';

import accountsModule from './accounts/accounts-store';
import notificationsModule from './notifications-store';
import requestModule from './requests-store';
import routerModule from './router-store';
import queriesModule from './queries/queries-store';

Vue.use(Vuex);

const injectionObj = {
  $http: Vue.prototype.$http,
};

export default new Vuex.Store({
  strict: true,
  modules: {
    accounts: accountsModule(injectionObj),
    notifications: notificationsModule(),
    request: requestModule(),
    router: routerModule(),
    queries: queriesModule(injectionObj),
  },
});
