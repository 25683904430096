import accountsActionModule from './queries-actions';

const initializeState = () => ({
  executions: [],
  total: 0,
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setQueryExecHistoryInStore(state, payload) {
      state.executions = payload.records || [];
      state.total = payload.total || 0;
    },
    updateQueryExecHistoryInStore(state, payload) {
      state.executions = payload.records.map((x) => {
        let obj = { ...x };
        if (obj.id === payload.id) {
          obj = {
            ...obj,
            ...payload,
          };
        }
        return obj;
      });
    },
  },
  actions: accountsActionModule({ $http }),
  getters: {
    executions: (state) => state.executions,
  },
});
