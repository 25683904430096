const { VUE_APP_BUILD } = process.env;

let poolId = 'ap-south-1_xlPqrN9ZD';
let userPoolClientId = '4tvergjpardcmdk9i0tp2q4u84';
let apiEndpoint = 'api-sandbox.auth.ap-south-1.amazoncognito.com';
let redirectUrls = ['http://localhost:8080/', 'https://sandbox.d1wdkcbaxi4elw.amplifyapp.com/', 'https://admin-sandbox.genome.genpact.com'];

if (VUE_APP_BUILD === 'dev') {
  poolId = 'ap-south-1_zAceUj28Z';
  userPoolClientId = '3lg7rkuhkdpgdmnorig78gv4r6';
  apiEndpoint = 'api-dev.auth.ap-south-1.amazoncognito.com';
  redirectUrls = ['http://localhost:8080/', 'https://dev.d3fqgqy3ezd824.amplifyapp.com/', 'https://admin-dev.genome.genpact.com'];
} else if (VUE_APP_BUILD === 'uat') {
  poolId = 'ap-south-1_zpNtAXRXy';
  userPoolClientId = '7m4tm1eofma6d4n5b9r12b7gmj';
  apiEndpoint = 'api-uat.auth.ap-south-1.amazoncognito.com';
  redirectUrls = ['https://uat.d2osijf13h9vtc.amplifyapp.com/', 'https://admin-genome-uat.genpact.com'];
} else if (VUE_APP_BUILD === 'production') {
  poolId = 'ap-south-1_s9vDtOoLq';
  userPoolClientId = '1scfa7lmlqthatfsrms54uomtu';
  apiEndpoint = 'api-production.auth.ap-south-1.amazoncognito.com';
  redirectUrls = ['https://production.d15hivzy5ht1ap.amplifyapp.com/', 'https://admin-genome.genpact.com/'];
}

const awsmobile = {
  aws_project_region: 'ap-south-1',
  // "aws_cognito_identity_pool_id": "ap-south-1:a1114f89-e743-40b0-b484",
  aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: poolId,
  aws_user_pools_web_client_id: userPoolClientId,
  oauth: {
    domain: apiEndpoint,
    scope: [
      // "phone",
      'email',
      'openid',
      'profile',
      // "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: redirectUrls.join(','),
    redirectSignOut: redirectUrls.map((x) => `${x}signout/`).join(','),
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

export default awsmobile;
