import Axios from 'axios';

const { VUE_APP_BUILD } = process.env || 'poc';

let API_GATEWAY_ID = '30d9om5ra9';
if (VUE_APP_BUILD === 'dev') API_GATEWAY_ID = 'ja3kjrvzk2';
else if (VUE_APP_BUILD === 'uat') API_GATEWAY_ID = '9i65yseef5';
else if (VUE_APP_BUILD === 'prod') API_GATEWAY_ID = 'o695vfezv9';

Axios.defaults.baseURL = `https://${API_GATEWAY_ID}.execute-api.ap-south-1.amazonaws.com/${VUE_APP_BUILD}`;
// Axios.defaults.headers.common['x-oauth-config-provider'] = 'cognito-aps1';

// TODO: REMOVE THESE
/*
  ==============================
  api.genpact.com based auth
  ==============================
*/
Axios.defaults.headers.common['x-oauth-config-provider'] = 'okta-acf';
/*
  ==============================
  end api.genpact.com based auth
  ==============================
*/

// Axios.defaults.headers.common.Accept = 'application/json';

export default (Vue) => {
  Object.defineProperties(Vue.prototype, {
    $http: {
      get() {
        return Axios;
      },
    },
  });
};
