import Vue from 'vue';
import VueRouter from 'vue-router';
// import { Auth } from 'aws-amplify';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard.Download',
        component: () => import(/* webpackChunkName: "dashboard.download" */ '@/views/Download.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/upload',
        name: 'Dashboard.Upload',
        component: () => import(/* webpackChunkName: "dashboard.upload" */ '@/views/Upload.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'Auth.Login',
    component: () => import(/* webpackChunkName: "auth.login" */ '@/views/Login.vue'),
  },
  {
    path: '/signout',
    name: 'Auth.Logout',
    component: () => import(/* webpackChunkName: "auth.logout" */ '@/views/Logout.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const createRouter = ({ store, $http }) => {
  const router = new VueRouter({
    routes,
    mode: 'history',
    fallback: false,
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
      if (from.meta.saveScrollPos) {
        store.dispatch('setViewScrollPos', {
          name: from.name,
          coordinates: {
            x: window.scrollX,
            y: window.scrollY,
          },
        });
      }
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
  });

  // router.beforeResolve((to, from, next) => {
  //   if (to.matched.some((record) => record.meta.requiresAuth)) {
  //     Auth.currentAuthenticatedUser()
  //       .then(async () => {
  //         if (!store.getters.isLoggedIn) await store.dispatch('getAmplifySession');
  //         next();
  //       })
  //       .catch((err) => {
  //         console.warn(err);
  //         next({
  //           path: '/login',
  //         });
  //       });
  //   }
  //   next();
  // });

  // TODO: REMOVE THESE
  /*
    ==============================
    api.genpact.com based auth
    ==============================
  */

  router.beforeEach(async (to, from, next) => {
    const { accessToken } = to.query;
    // $progress.start();
    try {
      if (accessToken) {
        $http.defaults.headers.common.Authorization = `Bearer ${accessToken}`; //eslint-disable-line
        const user = await store.dispatch('fetchUser');
        await store.dispatch('setSession', {
          user,
          accessToken,
        });

        const queryObj = { ...to.query };
        if (queryObj.accessToken) delete queryObj.accessToken;
        next({
          name: to.name || 'Dashboard.Download',
          query: queryObj,
          replace: true,
        });
      }
    } catch (e) {
      console.warn(e);
      store.dispatch('logout');
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.isLoggedIn) {
        const obj = {
          name: 'Auth.Login',
        };
        if (to.path) {
          // @ts-ignore
          obj.query = {
            redirect: to.path,
          };
        }
        next(obj);
      } else {
        next();
      }
    } else if (to.matched.some((record) => record.meta.redirectIfLoggedIn)) {
      if (store.getters.isLoggedIn) {
        next({
          name: 'Dashboard.Download',
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });
  /*
    ==============================
    end api.genpact.com based auth
    ==============================
  */

  router.afterEach(() => {
    // store.dispatch('clearViewScrollPos', to.name);
    // @ts-ignore
    if (store.state.router.next) store.dispatch('clearNextRoute');
  });

  return router;
};

export default createRouter;
