<template>
  <main id="leftbar" class="shadow-pane bg-white">
    <div class="flex flex-col text-sm">
      <router-link :to="{ name: 'Dashboard.Download' }" class="hover:bg-gray-100 flex items-center px-5 py-2 mt-4 text-gray-500 transition-colors" :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('Download') }">
        <FileTextIcon size="1.3x" class="mr-2" />
        Reports
      </router-link>
      <router-link :to="{ name: 'Dashboard.Upload' }" class="hover:bg-gray-100 flex items-center px-5 py-2 text-gray-500 transition-colors" :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('Upload') }">
        <UploadCloudIcon size="1.3x" class="mr-2" />
        Upload files
      </router-link>
    </div>
  </main>
</template>

<script>
import { UploadCloudIcon, FileTextIcon } from 'vue-feather-icons';

export default {
  name: 'TheSidebar',
  props: {
    navExpand: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadCloudIcon,
    FileTextIcon,
  },
  data() {
    return {
      // navExpand: true,
    };
  },
};
</script>

<style scoped>
.shadow-pane {
  box-shadow: 0 3px 15px #41597117;
}

.slide-enter-active,
.slide-leave-active {
  transition: width 0.2s linear;
}

.slide-enter-from,
.slide-leave-to {
  width: 0;
}
</style>
