export default ({ $http }) => ({
  async executeAthenaQuery(context, payload) {
    await $http.post('/api/genome/athena/v1/query', payload, {
      requestName: 'executeAthenaQuery',
    });
    context.dispatch('addToastNotifications', {
      text: 'Query executed successfully',
      timer: 4000,
    });
    context.dispatch('fetchQueryExecHistory', payload);
  },

  async regenerateReport(context, { id, timestamp }) {
    await $http.put(
      '/api/genome/athena/v1/query',
      { id, timestamp },
      {
        requestName: 'regenerateReport',
      },
    );
    context.dispatch('addToastNotifications', {
      text: 'Report regeneration request placed successfully',
      timer: 4000,
    });
  },

  async fetchQueryExecHistory(context, payload) {
    let queryString = '';
    Object.keys(payload || {}).forEach((key) => {
      if (payload[key]) queryString += `&${key}=${payload[key]}`;
    });
    const { data } = await $http.get(`/api/genome/athena/v1/query?${queryString}`, {
      requestName: 'fetchQueryExecHistory',
    });
    context.commit('setQueryExecHistoryInStore', data);
    // const { records } = data;
    // const isAnyReportBeingProcessed = records.find((x) => x.status !== 'completed');
    // console.log(isAnyReportBeingProcessed);
    return data;
  },

  async uploadFileToS3(context, { file, fileName }) {
    const { data } = await $http.get(`/api/genome/admin-reporting/v1/s3/signed-url?path=${fileName || file.name}&action=upload`, {
      requestName: 'uploadFileToS3',
    });

    const instance = $http.create({
      baseURL: data.url,
      headers: { 'Content-Type': file.type },
    });

    delete instance.defaults.headers.common.Authorization;
    const { data: response } = await instance.put('', file, {
      requestName: 'uploadFileToS3',
    });

    return response;
  },

  async downloadFileFromS3(context, { file, bucketName }) {
    const { data } = await $http.get(`/api/genome/admin-reporting/v1/s3/signed-url?path=${file}&action=download&bucketName=${bucketName}`, {
      requestName: 'downloadFileFromS3',
    });

    return data.url;
  },
});
