import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import Amplify from 'aws-amplify';
import '@/plugins';
import '@/filters';
import awsConfig from './aws-exports';
import App from './App.vue';
import createRouter from './router/index';
import store from './store/index';
import requestInterceptors from './modules/request-interceptors';
import '@/components/global';

const isLocalhost = Boolean(
  // [::1] is the IPv6 localhost address.
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] = awsConfig.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, productionRedirectSignOut] = awsConfig.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    responseType: 'token', // or 'code'. Code gets refresh token as well
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  },
  aws_appsync_region: 'ap-south-1', // Stack region
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS', // Primary AWS AppSync authentication type
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing: isLocalhost,
};

Amplify.configure(updatedAwsConfig);

Vue.config.productionTip = false;

const router = createRouter({ store, $progress: Vue.prototype.$Progress, $http: Vue.prototype.$http });

sync(store, router);

// store.dispatch('getAmplifySession').then(() => {
// TODO: REMOVE THESE
/*
  ==============================
  api.genpact.com based auth
  ==============================
*/
store.dispatch('getSessionFromLocalDb').then(() => {
  /*
  ==============================
  end api.genpact.com based auth
  ==============================
*/

  /*
  Intercept all axios requests
  and update its state in store
  */
  requestInterceptors({
    router,
    store,
    $http: Vue.prototype.$http,
    $progress: Vue.prototype.$Progress,
  });

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
