export default {
  data() {
    return {
      startDate: '',
      endDate: '',
      page: 1,
      size: 50,
    };
  },
  methods: {
    updateDate($event) {
      const { value } = $event.target;
      switch (value) {
        case 'today':
          this.page = 1;
          this.startDate = this.$moment(new Date()).format('YYYY-MM-DD');
          this.endDate = this.$moment(new Date()).format('YYYY-MM-DD');
          break;
        case '7days':
          this.page = 1;
          this.startDate = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
          this.endDate = this.$moment().subtract(1, 'days').format('YYYY-MM-DD');
          break;
        case '14days':
          this.page = 1;
          this.startDate = this.$moment().subtract(14, 'days').format('YYYY-MM-DD');
          this.endDate = this.$moment().subtract(1, 'days').format('YYYY-MM-DD');
          break;
        case 'month':
          this.page = 1;
          this.startDate = this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
          this.endDate = this.$moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
          break;
        default:
          break;
      }
    },
    valueUpdated({
      page,
      // size,
      startDate,
      endDate,
    }) {
      if (page) {
        this.$scrollTo('.element-header', 1000);
        this.page = page || this.page;
      }
      // this.size = size || this.size;
      if (startDate || endDate) {
        this.page = 1;
        this.startDate = startDate || this.startDate;
        this.endDate = endDate || this.endDate;
      }
    },
  },
};
