<template>
  <main class="flex w-full min-h-screen bg-gray-100">
    <div class="flex flex-col w-full">
      <Header :nav-expand="navExpand" @toggle-nav="navExpand = !navExpand" />
      <div v-if="userAccessAllowed" class="flex max-h-screen min-h-screen pt-16">
        <Sidebar class="w-72" v-if="navExpand" />
        <router-view class="w-full p-12 overflow-y-auto" />
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Dashboard',
  data() {
    return {
      navExpand: false,
      userAccessAllowed: false,
    };
  },
  async beforeMount() {
    try {
      await this.fetchUserRole();
      this.userAccessAllowed = true;
    } catch (e) {
      this.userAccessAllowed = false;
    }
  },
  methods: {
    ...mapActions(['fetchUserRole']),
  },
};
</script>
